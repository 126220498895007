

export default function useHelper(){

  const resolveUserStatusVariant = status => {
    if (status == 0) return 'secondary'
    if (status == 1) return 'success'

    return 'primary'
  }
  const resolveUserStatusText = status => {
    if (status == 0) return 'Nein'
    if (status == 1) return 'Ja'

    return ''
  }

  const downloadCSVData = (csvString,fileName) => {
    let filename = fileName || (Date.now()+'_download.csv');
    const anchor = document.createElement('a');
    anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvString);
    anchor.target = '_blank';
    anchor.download = filename;
    anchor.click();
  }

  return {
    resolveUserStatusVariant,
    resolveUserStatusText,
    downloadCSVData,
  }
}
